import { useEffect, useState } from "react";

import { baseUrl } from "./constants";

export type SellerResponse = {
  email: string;
  name: string;
  discordId: string;
  role: number;
  billing: Billing;
  plan_tier: string;
};

export type Seller = {
  email: string;
  name: string;
  discordId: string;
  role: number;
  billing: Billing;
  plan_tier: string;
};

export type Billing = {
  subscription_status: string;
  subscription_expiration_date: string;
  stripe_customer_id: string;
  session_id: string;
};

export const useSeller = () => {
  const [loading, setLoading] = useState(true);
  const [seller, setSeller] = useState<Seller>({
    email: "",
    name: "",
    discordId: "",
    role: 0,
    billing: {
      subscription_status: "",
      subscription_expiration_date: "",
      stripe_customer_id: "",
      session_id: "",
    },
    plan_tier: "",
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getSeller = async () => {
      try {
        const url = new URL("/seller", baseUrl);
        const resp = await fetch(url, { credentials: "include" });

        if (resp.status === 200) {
          const body: SellerResponse = await resp.json();
          const data = {
            email: body.email,
            name: body.name,
            discordId: body.discordId,
            role: body.role,
            billing: body.billing,
            plan_tier: body.plan_tier,
          };
          setSeller(data);
          setLoading(false);
          setError(null);
        } else {
          setError(`An unknown error has occured.`);
        }
      } catch (e) {
        setError("An unknown error has occured.");
        console.error("Error during useSeller: ", e);
      }
    };

    getSeller();
  }, []);

  return {
    loading,
    seller,
    setSeller,
    error,
  };
};

export const updateSellerName = async (name: string) => {
  try {
    const requestBody = JSON.stringify({ name });
    const url = new URL("/seller/name", baseUrl);
    const resp = await fetch(url, {
      method: "PUT",
      body: requestBody,
      credentials: "include",
    });
    if (!resp.ok) {
      const body = await resp.text();
      return body;
    }
    return null;
  } catch (e) {
    if (e instanceof Error) {
      return e.toString();
    }
    return `An unknown error occured while updating seller name resource.`;
  }
};

export const updateSellerEmail = async (email: string) => {
  const postSellerBody = {
    email: email,
  };
  try {
    const url = new URL("/seller/email", baseUrl);
    const resp = await fetch(url, {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(postSellerBody),
    });
    if (resp.status === 200) {
      return null;
    } else {
      const body = await resp.text();
      const bodyJson = JSON.parse(body);
      if (bodyJson.errors[0].cause.Message === 'duplicate key value violates unique constraint "uq_seller_email"') {
        return "Email already exists.";
      }
      return "An unknown error occured while updating seller email resource.";
    }
  } catch (e) {
    return "An unknown error occured while updating seller email resource.";
  }
};

export type updateEmailError = {
  result: string;
  code: number;
  errors: {
    title: string;
    description: string;
    id: string;
    cause: {
      Severity: string;
      Code: string;
      Message: string;
      Detail: string;
    };
  }[];
};

export const postReferralSource = async (source: string, value: string) => {
  try {
    const requestBody = JSON.stringify({ source, value });
    const url = new URL("/seller/referral", baseUrl);
    const resp = await fetch(url, {
      method: "POST",
      body: requestBody,
      credentials: "include",
    });
    if (!resp.ok) {
      const body = await resp.text();
      return body;
    }
    return null;
  } catch (e) {
    if (e instanceof Error) {
      return e.toString();
    }
    return `An unknown error occured while updating seller name resource.`;
  }
};

export const createCheckoutSession = async (priceId: string, router: any) => {
  try {
    // call the backend to create subscription
    const url = new URL("/stripe/create-checkout-session", baseUrl);
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        price_id: priceId,
      }),
    });

    if (response.status !== 200) {
      // throw error
      throw new Error("Error creating checkout session");
    }

    const body = await response.json();
    window.location.href = body.redirect_url;
  } catch (error) {
    router.push("/register");
  }
};

export const createReferralCheckoutSession = async (
  phone: string,
  priceId: string,
  affiliate: string | null,
  source: string | null,
  referral: string | null,
  router: any,
) => {
  try {
    // call the backend to create subscription
    const url = new URL("/stripe/create-referral-checkout-session", baseUrl);
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: phone,
        price_id: priceId,
        affiliate: affiliate,
        source: source,
        referral: referral,
      }),
    });

    if (response.status !== 200) {
      // throw error
      throw new Error("Error creating checkout session");
    }

    const body = await response.json();
    window.location.href = body.redirect_url;
    return null;
  } catch (error) {
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    console.log(error?.status);
    router.push("/referral?aff=" + affiliate + "&src=" + source + "&ref=" + referral);
    return "An unknown error has occured.";
  }
};

export const createPortalSession = async (seller: Seller, affiliate: string, source: string, referral: string) => {
  try {
    if (seller.billing.session_id === "") {
      window.location.href = "/free-trial" + "?affiliate=" + affiliate + "&source=" + source + "&referral=" + referral;
    } else {
      // call the backend to create subscription
      const url = new URL("/stripe/create-portal-session", baseUrl);
      const sessionId = seller.billing.session_id;
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_id: sessionId,
        }),
      }).then(async (res) => {
        const body = await res.json();
        window.location.href = body.redirect_url;
      });
    }
  } catch (error) {
    console.log(error);
  }
};
