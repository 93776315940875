"use client";

import Link from "next/link";
import Image from "next/image";
import "./styles.css";
import { useSearchParams } from "next/navigation";
import { useLogout } from "../lib/api/useLogout";
import { useSeller } from "../lib/api/useSeller";

const Footer = () => {
  const { logout } = useLogout();
  const { seller } = useSeller();

  const searchParams = useSearchParams();
  const affiliate = searchParams?.get("affiliate");
  const source = searchParams?.get("source");
  const referral = searchParams?.get("referral");

  const params = "?affiliate=" + affiliate + "&source=" + source + "&referral=" + referral;

  const handleLogoutClick = async () => {
    await logout();
  };

  const sections = [
    {
      title: "PRODUCT",
      links: [
        { text: "Components", link: "/" },
        { text: "Pricing", link: "/pricing" },
        { text: "Dashboard", link: "/dashboard" },
        { text: "Feature requests", link: "https://discord.gg/hKbvHAHvvU" },
      ],
    },
    {
      title: "FEATURES",
      links: [
        { text: "Generate item specs", link: "/dashboard" },
        { text: "Bulk list", link: "/dashboard" },
        { text: "Price comparisons", link: "/dashboard" },
      ],
    },
    {
      title: "RESOURCES",
      links: [
        { text: "Blog", link: "/blog" },
        { text: "Support", link: "https://discord.gg/hKbvHAHvvU" },
        { text: "Community", link: "https://discord.gg/hKbvHAHvvU" },
      ],
    },
    {
      title: "COMPANY",
      links: [
        { text: "FAQs", link: "/#faq-section" },
        { text: "Careers", link: "info@sellraze.com" },
        { text: "About", link: "/about" },
      ],
    },
  ];

  return (
    <footer className="mt-24 mx-12 lg:mx-32 space-y-14">
      <div className="[ row-between ] flex flex-wrap">
        <Image
          src="/sellRazeMetallic.png"
          height="326"
          width="326"
          alt="Sellraze Metallic Logo"
          className="absolute bottom-0 right-0 opacity-5 -z-10"
        />
        <div className="flex flex-col lg:flex-row w-full justify-between items-start">
          <div className="flex flex-row w-full lg:flex-col h-full justify-between mb-12">
            <Link href={"/" + params}>
              <Image src="/sellRazeLogo.png" height="30" width="128" alt="Sellraze Logo" />
            </Link>
            <div className="flex flex-row space-x-4 align-center">
              <Link href="https://www.x.com/sellraze" target="_blank" rel="noopener noreferrer" className="opacity-70 hover:opacity-100 transition-all duration-200">
                <Image src="/iconX.png" height="16" width="16" alt="X Icon" />
              </Link>
              <Link href="https://www.linkedin.com/company/sellraze" target="_blank" rel="noopener noreferrer" className="opacity-70 hover:opacity-100 transition-all duration-200">
                <Image src="/iconLinkedin.png" height="16" width="16" alt="LinkedIn Icon" />
              </Link>
              <Link href="https://discord.gg/hKbvHAHvvU" target="_blank" rel="noopener noreferrer" className="opacity-70 hover:opacity-100 transition-all duration-200">
                <Image src="/iconDiscord.png" height="16" width="16" alt="Discord Icon" />
              </Link>
              <Link href="https://www.instagram.com/sellraze" target="_blank" rel="noopener noreferrer" className="opacity-70 hover:opacity-100 transition-all duration-200">
                <Image src="/iconInstagram.png" height="16" width="16" alt="Instagram Icon" />
              </Link>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-8 lg:gap-20 w-full">
            {sections.map((section) => (
              <div className="flex flex-col space-y-[20px] min-w-[150px] flex-1" key={section.title}>
                <p className="font-sfmono text-dark-32 text-[16px]">{section.title}</p>
                <div className="flex flex-col space-y-[10px]">
                  {section.links.map((link) => (
                    <Link
                      className="font-sans text-base leading-normal text-neutral-950 text-opacity-70 whitespace-nowrap"
                      href={link.link + params}
                      key={`${link.link} + ${link.text}`}
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
      <div className="[ row-between m ] flex-wrap py-5 border-t items-center">
        <div className="inline-flex flex-col lg:flex-row items-start justify-start gap-2.5">
          <div className="font-sans text-[11px] leading-none text-neutral-950 text-opacity-50">© SellRaze 2024.</div>
          <div className="flex flex-row gap-2.5">
            <Link className="cursor-pointer font-sans text-[11px] leading-none text-neutral-950 text-opacity-50" href="/tos">
              Terms
            </Link>
            <Link
              className="cursor-pointer font-sans text-[11px] leading-none text-neutral-950 text-opacity-50"
              href="/privacy"
            >
              Privacy
            </Link>
          </div>

        </div>
        <div className="flex flex-row space-x-4 items-center">
          <div className="font-sans text-[11px] leading-none text-neutral-950 text-opacity-50">Download mobile</div>
          <Link
            className="cursor-pointer font-sans leading-none opacity-50 hover:opacity-100 transition-all duration-200"
            href="https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085?itsct=apps_box_badge&amp;itscg=30200"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/iconAppStore.png" height="16" width="16" alt="App Store Icon" />
          </Link>
          <Link
            className="cursor-pointer font-sans leading-none opacity-50 hover:opacity-100 transition-all duration-200"
            href="https://play.google.com/store/apps/details?id=com.sellraze.scanner&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/iconGooglePlay.png" height="16" width="16" alt="Google Play Icon" />
          </Link>
        </div>
      </div>
      {/* <p className="[ padding-2 ] [ text-18 fg-min-grey ]">2024 &copy; SellRaze Inc. All rights reserved</p> */}
    </footer>
  );
};

export default Footer;